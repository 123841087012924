var render = function() {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-row",
    [
      _c(
        "a-col",
        [
          _c(
            "a-form-model-item",
            {
              staticStyle: { "margin-top": "1rem" },
              attrs: { label: _vm.$t(_vm.formRules.nik.label) }
            },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: _vm.formRules.nik.decorator,
                    expression: "formRules.nik.decorator"
                  }
                ],
                attrs: {
                  name: _vm.formRules.nik.name,
                  placeholder: _vm.$t(_vm.formRules.nik.placeholder)
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: _vm.$t(_vm.formRules.gender.label) } },
            [
              _c(
                "a-radio-group",
                {
                  on: { change: _vm.handleChangeGender },
                  model: {
                    value: _vm.selectedRadio,
                    callback: function($$v) {
                      _vm.selectedRadio = $$v
                    },
                    expression: "selectedRadio"
                  }
                },
                [
                  _c("a-radio", { attrs: { value: "Male" } }, [
                    _vm._v(" Male ")
                  ]),
                  _c("a-radio", { attrs: { value: "Female" } }, [
                    _vm._v(" Female ")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: _vm.$t(_vm.formRules.role.label) } },
            [
              _c(
                "a-select",
                {
                  directives: [
                    {
                      name: "decorator",
                      rawName: "v-decorator",
                      value: _vm.formRules.role.decorator,
                      expression: "formRules.role.decorator"
                    }
                  ],
                  attrs: {
                    name: _vm.formRules.role.name,
                    placeholder: _vm.$t(_vm.formRules.role.placeholder),
                    showSearch: true,
                    "option-filter-prop": "children",
                    "filter-option": _vm.filterOption,
                    allowClear: true
                  },
                  on: {
                    search: function(value) {
                      return this$1.$emit("handleSearchDepartment", value)
                    }
                  }
                },
                _vm._l(_vm.computedDataDepartment, function(data, index) {
                  return _c(
                    "a-select-option",
                    { key: index, attrs: { value: data.value } },
                    [
                      _c(
                        "a-tooltip",
                        [
                          _c("template", { slot: "title" }, [
                            _vm._v(" " + _vm._s(data.value) + " ")
                          ]),
                          _vm._v(" " + _vm._s(data.value) + " ")
                        ],
                        2
                      )
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: _vm.$t(_vm.formRules.dateOfBirth.label) } },
            [
              _c("a-date-picker", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: _vm.formRules.dateOfBirth.decorator,
                    expression: "formRules.dateOfBirth.decorator"
                  }
                ],
                attrs: {
                  name: _vm.formRules.dateOfBirth.name,
                  format: _vm.DEFAULT_DATE_FORMAT
                },
                on: { change: _vm.handleChangeDateOfBirth }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: _vm.$t(_vm.formRules.startingDate.label) } },
            [
              _c("a-date-picker", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: _vm.formRules.startingDate.decorator,
                    expression: "formRules.startingDate.decorator"
                  }
                ],
                attrs: {
                  name: _vm.formRules.startingDate.name,
                  format: _vm.DEFAULT_DATE_FORMAT
                },
                on: { change: _vm.handleChangeStartingDate }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: _vm.$t(_vm.formRules.employeeNumber.label) } },
            [
              _c("p", [
                _vm._v(_vm._s(_vm.employeeNumber ? _vm.employeeNumber : null))
              ])
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }